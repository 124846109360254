import { Layout, Row, Col, Menu, Card, Avatar, PageHeader, List, Statistic, Spin, Button, Tag, Checkbox, Empty, Descriptions } from 'antd';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { AgoraData } from './data'


export default function NewsFeed() {

    let { id } = useParams();
    const [data, setData]: [any, any] = useState({})
    const qid = AgoraData[id].qid
    console.log(qid)

    useEffect(() => {

        const loadOverview = async () => {
            let response = await fetch(`https://www.wikidata.org/wiki/Special:EntityData/${qid}.json`)
            let body = await response.json()
            setData(body.entities[qid])
        }

        loadOverview()
    }, [])        

    return (
            <Descriptions title="Wiki Info">
                {Object.keys(data).map((s: any) => {
                    console.log(s, data[s])
                    if (s !== 'labels' && s !== 'descriptions' && s !== 'aliases' && s !== 'claims' && s !== 'sitelinks') {
                        return <Descriptions.Item label={s}> {JSON.stringify(data[s])} </Descriptions.Item>
                    }
                })}
            </Descriptions>
    )
}