import { combineReducers } from '@reduxjs/toolkit'
import insertEntityReducer from '../containers/InsertEntity/insertEntitySlice'
import insertTripleReducer from '../containers/InsertTriple/insertTripleSlice'
import updateReducer from '../containers/Update/updateSlice'
import discoveryReducer from '../containers/Home/discoverySlice'
import searchReducer from '../containers/Search/searchSlice'
import entityReducer from '../containers/Profile/profileSlice'
import userReducer from './rootSlice'

const rootReducer = combineReducers({
  insertEntityReducer: insertEntityReducer,
  insertTripleReducer: insertTripleReducer,
  discoveryReducer: discoveryReducer,
  updateReducer: updateReducer,
  searchReducer: searchReducer,
  entityReducer: entityReducer,
  userReducer: userReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer