import { IEntity } from "agora-types";
import { AgoraData } from "../Profile/data"
const entities: IEntity[] = Object.keys(AgoraData).map((user: string) => {
    return {
        statements: [
            {
                subject: {
                    value: `https://agora-data.com/${user}`,
                    kind: 'uri'
                },
                predicate: {
                    value: 'https://agora-data.com/Property/label',
                    kind: 'uri',
                    label: 'label'
                },
                object: {
                    kind: 'literal',
                    value: AgoraData[user].label,
                    dataType: {
                        value: 'https://agora-data.com/Class/Text',
                        kind: 'uri'
                    }
                }
            },
            {
                subject: {
                    value: 'https://agora-data.com/felly',
                    kind: 'uri'
                },
                predicate: {
                    value: "https://agora-data.com/Property/instanceOf",
                    kind: 'uri',
                    label: 'instanceOf'
                },
                object: {
                    kind: 'uri',
                    value: `${AgoraData[user].instance}`
                }
            },
            {
                subject: {
                    value: 'https://agora-data.com/felly',
                    kind: 'uri'
                },
                predicate: {
                    value: 'https://agora-data.com/Property/description',
                    kind: 'uri',
                    label: 'description'
                },
                object: {
                    kind: 'literal',
                    value: AgoraData[user].description,
                    dataType: {
                        value: 'https://agora-data.com/Class/Text',
                        kind: 'uri'
                    }
                }
            }
        ]
    }
})

export default entities;