import { Form, Input, Button, Checkbox } from 'antd';
import React from 'react';
import { properties } from './mock'
import { IProp } from 'agora-types';

interface InsertProfileFormProps {
    form: any
}

export default function InsertProfileForm(props: InsertProfileFormProps) : JSX.Element {

    

  return (
    <Form
        form={props.form}
        name="basic"
        layout="vertical"
    >
      <Form.Item
      label="Label"
        rules={[{ required: true, message: 'Please input a Label!' }]}
      >
        <Input placeholder="Label"/>
      </Form.Item>

      {properties.map((p: IProp) => {
          return <Form.Item
          label={p.label}
          >
            <Input placeholder={p.description}/>
          </Form.Item>
      })}

    </Form>
  );
};