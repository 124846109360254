import React, { Component, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Form, Input, Card } from 'antd';
import { Link } from 'react-router-dom';
import {
    LockOutlined,
    UserOutlined
} from '@ant-design/icons';
import { generateId } from '../utils/dbUtils'
// @ts-ignore
import { useNavigate } from "react-router-dom"

function CustomSignUp(): JSX.Element {
    const navigate = useNavigate();

    const [password, setPassword]: [string, any] = useState('');
    const [confirmPassword, setConfirmPassword]: [string, any] = useState('');
    const [email, setEmail]: [string, any] = useState('');
    const [confirmationCode, setConfirmationCode]: [string, any] = useState('');
    const [verified, setVerified]: [boolean, any] = useState(false);
  
    const signUp = () => {

        Auth.signUp({
            username: email,
            password: password,
            attributes: {
                profile: generateId()
            }
        })
        .then(() => {
            console.log('Successfully signed up');
        })
        .catch((err) => console.log(`Error signing up: ${ err }`))
    }
  
    const confirmSignUp = () => {
        Auth.confirmSignUp(email, confirmationCode)
        .then(() => {
            console.log('Successfully confirmed signed up')
            navigate('/login')
            // props.handleSignup();
        })
        .catch((err) => console.log(`Error confirming sign up - ${ err }`))
    }
  
    const handleSubmit = () => {
            
        if (verified) {
          confirmSignUp();
        } else {
          signUp();
          setPassword('')
          setConfirmPassword('')
          setVerified(true)
        }
    }
  
    const handleChange = (e: any) => {

        if (e.target.id === 'password') {
            setPassword(e.target.value)
        } else if (e.target.id === 'email') {
            setEmail(e.target.value)
        } else if (e.target.id === 'confirmationCode') {
            setConfirmationCode(e.target.value)
        } else if (e.target.id === 'confirm_password') {
            setConfirmPassword(e.target.value)
        }
    }
  
    if (verified) {
        return (
            <Card title="Confirmation Code">
                <Form
                    onFinish={() => handleSubmit()}
                    scrollToFirstError
                >
                    <Form.Item>
                        <Input 
                            value={confirmationCode}
                            id='confirmationCode'
                            onChange={handleChange}
                            placeholder="Confirmation Code" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit"> Confirm Sign Up </Button>
                    </Form.Item>
                </Form>
            </Card>
        );
      } else {
        return (
          <Card title="Register">
            <Form
                onFinish={() => handleSubmit()}
                scrollToFirstError
            >
                <Form.Item
                    name="email"
                    rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                    ]}
                >
                    <Input 
                        value={email}
                        id="email"
                        onChange={handleChange}
                        prefix={<UserOutlined className="site-form-item-icon" />} 
                        placeholder="Email" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    ]}
                    hasFeedback
                >
                    <Input
                        value={password}
                        id='password'
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        onChange={handleChange}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject('The two passwords that you entered do not match!');
                        },
                    }),
                    ]}
                >
                    <Input
                        value={confirmPassword}
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        onChange={handleChange}
                        id="confirm_password"
                        placeholder="Confirm Password"
                    />
                </Form.Item>

                <Form.Item>
                    <div>
                        Already a User? 
                        <Link to='/login'> Login </Link>
                    </div>
                </Form.Item>
                <Form.Item >
                    <Button type="primary" htmlType="submit">
                    Register
                    </Button>
                </Form.Item>
        </Form>
        </Card>
        );
    }
}

export default CustomSignUp;