import { Divider, PageHeader, Layout, Button, Tabs } from 'antd';
import React from 'react';
import ProfileFeed from '../../components/Visualizations/Feed/ProfileFeed/ProfileFeed'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import entities from './dummy_entities'
// @ts-ignore
import { useNavigate } from "react-router-dom"
import { onClassFilterChange } from './discoverySlice';
import { setModal } from 'containers/InsertEntity/insertEntitySlice';

const { Content } = Layout
const { TabPane } = Tabs;


const ProfileHome: React.FC = () => {

    const dispatch = useDispatch()
    const { loading, selected, classFilter } = useSelector((state: RootState) => state.discoveryReducer)
    const navigate = useNavigate()
    const classFilterSet = new Set(classFilter)

    const onFilterChange = (checked: boolean, cls: string) => {

        if (checked) {
          classFilterSet.add(cls)
        } else {
            classFilterSet.delete(cls)
        }
  
        console.log(checked, cls, classFilterSet)
        dispatch(onClassFilterChange(Array.from(classFilterSet)))
    }
        return (
          <Layout style={{ padding: '24px' }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: '#fff'
              }}
            >
              <PageHeader
                className="site-page-header"
                title={"Aggregated Profiles"}
                style={{'border': '1px solid rgb(235, 237, 240)', paddingBottom: 20}}
                extra={[
                  <Button key={1} type="primary" onClick={() => dispatch(setModal({modal: true, insert: true}))}>
                  Add Item +
              </Button>,
                   
              ]}
            />   

    <Divider />

            <ProfileFeed
                entities={entities}
                loading={false}
                onSelectEntity={(label: string) => navigate(`/entity/${label.replace(/\s/g, "").toLowerCase()}`)} /> 
    
              <Divider />
            </Content>
          </Layout>
    )
   
    // }
};

export default ProfileHome