import IAgoraExProfile from "external_sources/IAgoraExProfile";
import IAgoraPost from "external_sources/IAgoraPost";


export const AgoraData: {
    [id: string]: {
        youtube: string
        instagram: string
        twitter: string,
        label: string,
        instance: string,
        description: string,
        newsEndpoint: string,
        qid: string
    }
} = {
    "nasa" : {
        qid: 'Q23548',
        newsEndpoint: 'https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=24H&query=nasa%20sourcecountry:US&mode=artlist&maxrecords=75&format=json&sort=hybridrel',
        youtube: 'UCLA_DiR1FfKNvjuUpBHmylQ',
        instagram: 'nasa',
        twitter: 'nasa',
        label: 'NASA',
        instance: "Organization",
        description: `The National Aeronautics and Space Administration is an independent agency of the United States Federal Government responsible for the civilian space program, as well as aeronautics and space research. NASA was established in 1958, succeeding the National Advisory Committee for Aeronautics.`,
    },
    "lebronjames" : {
        qid: 'Q23548',
        newsEndpoint: 'https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=24H&query=lebron%20james%20sourcecountry:US&mode=artlist&maxrecords=75&format=json&sort=hybridrel',
        label: 'Lebron James',
        instance: "Person",
        description: `LeBron Raymone James Sr. is an American professional basketball player for the Los Angeles Lakers of the National Basketball Association. He is widely considered to be one of the greatest basketball players in NBA history.`,
        youtube: 'UCV9dV3DcAZWGnT2yFNKxStA',
        instagram: 'kingjames',
        twitter: 'KingJames'
    },
    "nationalgeographic" : {
        qid: 'Q5973845',
        newsEndpoint: 'https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=24H&query=national%20geographic%20sourcecountry:US&mode=artlist&maxrecords=75&format=json&sort=hybridrel',
        label: 'National Geographic',
        instance: "Organization",
        description: `National Geographic is an American pay television network and flagship channel that is owned by National Geographic Partners, a joint venture between The Walt Disney Company and the National Geographic Society, with the operational management handled by Walt Disney Television.`,
        youtube: 'UCpVm7bg6pXKo1Pr6k5kxG9A',
        instagram: 'natgeo',
        twitter: 'natgeo'
    },
    "patagonia" : {
        qid: 'Q5973845',
        newsEndpoint: 'https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=24H&query=nasa%20sourcecountry:US&mode=artlist&maxrecords=75&format=json&sort=hybridrel',
        label: 'Patagonia',
        instance: "Organization",
        description: `Patagonia, Inc. is an American clothing company that markets and sells outdoor clothing. The company was founded by Yvon Chouinard in 1973, and is based in Ventura, California. Its logo is the outline of Mount Fitz Roy, the border between Chile and Argentina, in the region of Patagonia.`,
        youtube: 'UCl3xZ-f3cQhOHvH6f-7-ssQ',
        instagram: 'patagonia',
        twitter: 'patagonia'
    },
    "daviddobrik" : {
        qid: 'Q50574810',
        newsEndpoint: 'https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=24H&query=nasa%20sourcecountry:US&mode=artlist&maxrecords=75&format=json&sort=hybridrel',
        label: 'David Dobrik',
        instance: "Person",
        description: `Dávid Julian Dobrík is a Slovak YouTube personality. He found early success on the video sharing platform Vine, before creating a vlog-oriented YouTube channel in late 2014.`,
        youtube: 'UCmh5gdwCx6lN7gEC20leNVA',
        instagram: 'daviddobrik',
        twitter: 'DavidDobrik'
    },
    "davematthewsband" : {
        qid: 'Q832086',
        newsEndpoint: 'https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=24H&query=nasa%20sourcecountry:US&mode=artlist&maxrecords=75&format=json&sort=hybridrel',
        label: 'Dave Matthews Band',
        instance: "Group",
        description: `Dave Matthews Band is an American rock band, formed in Charlottesville, Virginia in 1991. `,
        youtube: 'UCQv0bxIpI_K0zmmCBTOttAQ',
        instagram: 'davematthewsband',
        twitter: 'davematthewsbnd'
    }
}

export const platformData: {
    [platform: string]: {
        [username: string]: {
            profile: IAgoraExProfile,
            posts: IAgoraPost[]
        }
    }
} = {
    'youtube': {
        "nasa" : {
            profile: {
                "username": "nasa",
                "uid": 'UCLA_DiR1FfKNvjuUpBHmylQ',
                "source": "youtube",
                "followers": 5650000,
                "link": "https://www.youtube.com/channel/UCLA_DiR1FfKNvjuUpBHmylQ"
            },
            posts: [
                {
                    "uid": '',
                    "source": "youtube",
                    "title": "Discussing a Safe Return to On-site Work on This Week @NASA – May 8, 2020",
                    "date": "May 8, 2020",
                    "description": "",
                    "media": "https://i.ytimg.com/vi/iezhtq-OLx0/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCzOe4Wy_N4Ps921tzWVFx8oEWDcg",
                    "link": "https://www.youtube.com/watch?v=iezhtq-OLx0"
                }
            ]
        },
        "lebronjames" : {
            profile: {
                "username": "lebronjamescom",
                "uid": 'UCLA_DiR1FfKNvjuUpBHmylQ',
                "source": "youtube",
                "followers": 32900,
                "link": "https://www.youtube.com/user/lebronjamescom"
            },
            posts: []
        },
        "nationalgeographic" : {
            "profile": {
                "username": "National Geographic",
                "uid": 'UCLA_DiR1FfKNvjuUpBHmylQ',
                "source": "youtube",
                "followers": 14100000,
                "link": "https://www.youtube.com/user/NationalGeographic"
            },
            "posts" : [
                {
                    "uid": '',
                    "source": "youtube",
                    "title": "Shadows of Forgotten Ancestors | Cosmos: Possible Worlds",
                    "date": "May 8, 2020",
                    "description": `What makes us uniquely human? Neil deGrasse Tyson discusses the forms of heroism found in the animal kingdom outside of humans.
                    ➡ Subscribe: http://bit.ly/NatGeoSubscribe
                    
                    About Cosmos: Possible Worlds:
                    Helmed by Ann Druyan and hosted by Neil deGrasse Tyson, COSMOS: POSSIBLE WORLDS is a triumphant voyage through humanity’s past, present, and future, taking viewers to previously unchartered territories and turning complex themes of science and exploration into a mind-blowing adventure beyond the realms of the imagination. Approximately 13.8 billion years in the making – the age of the universe –COSMOS: POSSIBLE WORLDS is a wonder-filled odyssey that maps a hopeful vision of our future.
                    
                    About National Geographic:
                    National Geographic is the world's premium destination for science, exploration, and adventure. Through their world-class scientists, photographers, journalists, and filmmakers, Nat Geo gets you closer to the stories that matter and past the edge of what's possible.
                    
                    Get More National Geographic:
                    Official Site: http://bit.ly/NatGeoOfficialSite
                    Facebook: http://bit.ly/FBNatGeo
                    Twitter: http://bit.ly/NatGeoTwitter
                    Instagram: http://bit.ly/NatGeoInsta
                    
                    Shadows of Forgotten Ancestors | Cosmos: Possible Worlds
                    https://youtu.be/4xiAQIL0GyI
                    
                    National Geographic
                    https://www.youtube.com/natgeo`,
                    "media": "https://i.ytimg.com/vi/4xiAQIL0GyI/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLANiguDJTPb_gez-OijdK3LbiHbEQ",
                    "link": "https://www.youtube.com/watch?v=4xiAQIL0GyI"
                }
            ]
        },
        "patagonia" : {
            "profile": {
                "username": "patagonia",
                "uid": 'UCLA_DiR1FfKNvjuUpBHmylQ',
                "source": "youtube",
                "followers": 5650000,
                "link": "https://www.youtube.com/user/patagoniavideo"
            },
            "posts" : [
                {
                    "uid": '',
                    "source": "youtube",
                    "title": "Lessons from Jeju Trailer | Freediving and Motherhood with Kimi Werner",
                    "date": "May 4, 2020",
                    "description": `Seven months pregnant and apprehensive of the effect motherhood would have on her career as a professional freediver, Kimi Werner took a trip to the island of Jeju in South Korea to meet her heroes, the haenyeo – a group of freediving and fishing women often regarded as Korea’s first working mother’s whose culture dates back centuries. 

                    Expecting her first child, Kimi will explore how the haenyeo represent feminine strength and resilience as a self sufficient sisterhood of diving mothers and grandmothers. As Kimi transitions from a solo underwater adventurist to a mother cultivating a family, she longs to learn from the haenyeo elders - many of which are well into their 70’s and continue to dive. 
                    
                    Join Kimi on her journey in Lessons from Jeju, where she gains a deeper understanding of what it means to pass on her own legacy and learns motherhood for a professional athlete isn’t a roadblock but instead a path forward.
                    
                    Directed by Nicole Gormley
                    DP/Editor: Chris Naum 
                    Underwater DP: Justin Turkowski 
                    Audio/Translator: Nancy Kwon 
                    Produced by: Backroads Pictures`,
                    "media": "https://i.ytimg.com/vi/tPw-pqBQI-M/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLA36-5Vs4Xw8JkgenbeLqpxrv7K4Q",
                    "link": "https://www.youtube.com/watch?v=tPw-pqBQI-M"
                }
            ]
        },
        "daviddobrik" : {
            "profile": {
                "username": "David Dobrick",
                "uid": 'UCLA_DiR1FfKNvjuUpBHmylQ',
                "source": "youtube",
                "followers": 17200000,
                "link": "https://www.youtube.com/channel/UCmh5gdwCx6lN7gEC20leNVA"
            },
            "posts" : [
                {
                    "uid": '',
                    "source": "youtube",
                    "title": "WE CANT BELIEVE THIS HAPPENED!!",
                    "date": "April 24, 2020",
                    "description": `This was a lot of fun!! I MISS THIS
    
                    Thank you to EA for helping me surprise some people!! Stay safe and stay in and play!!
                    
                    Text me for the next time we do surprise so I can let you know:
                    847-250-9391
                    
                    Donate here to Americas Food Fund: https://www.gofundme.com/f/americasfo...
                    
                    THE NEW DAVIDS VLOG MERCH IS HERE: 
                    https://fanjoy.co/collections/david-d...   
                    
                    SUBSCRIBE TO JOE HE IS GREAT: https://www.youtube.com/watch?v=8YeIi...
                     
                    GO TO MY INSTAGRAM FOR MORE CONTENT: https://www.instagram.com/daviddobrik... 
                    
                    CHECKOUT OUR PODCAST WHERE WE TALK ABOUT RELATIONSHIPS:  https://itunes.apple.com/us/podcast/v...   
                        
                    ADD ME ON SNAPCHAT TO KEEP UP WITH OUR VEGAS TRIP: @Daviddobrik  
                    
                    WATCH MY NEW HOUSE TOUR HERE: https://www.youtube.com/watch?v=C4yEC...   
                    
                    Comment how much you love our bunny if you read this!!
                    
                    ADD ME ON SNAPCHAT TO BE INVITED TO OUR HOUSE NEXT: 
                    @DavidDobrik
                    
                    Thanks for watching :) Throww it a like if you like throwing stuff!
                    
                    Turn my notifications on these to be the next shoutout!!`,
                    "media": "https://i.ytimg.com/vi/hWzccW4TQkg/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLDyGnQYpiq3oKKpI7zsENxPTZjd1Q",
                    "link": "https://www.youtube.com/watch?v=hWzccW4TQkg"
                }
            ]
        },
        "davematthewsband" : {
            "profile": {
                "username": "Dave Matthews Band",
                "uid": 'UCLA_DiR1FfKNvjuUpBHmylQ',
                "source": "youtube",
                "followers": 2720000,
                "link": "https://www.youtube.com/channel/UCQv0bxIpI_K0zmmCBTOttAQ"
            },
            "posts" : [
                {
                    "uid": '',
                    "source": "youtube",
                    "title": "Dave Matthews Band - Crash Into Me (Official Video)",
                    "date": "October 3, 2019",
                    "description": `Dave Matthews Band's official music video for 'Crash Into Me'. Click to listen to Dave Matthews Band on Spotify: http://smarturl.it/DMBSpot?IQid=DMBCIM
    
                    As featured on The Best Of What's Around: Vol. 1. Click to buy the track or album via iTunes: http://smarturl.it/DMBBestOf1iTunes?I...
                    Google Play: http://smarturl.it/DMBCIMplay?IQid=DM...
                    Amazon: http://smarturl.it/DMBBestOf1Amz?IQid...
                    
                    More from Dave Matthews Band
                    Space Between: https://youtu.be/Wi9HjkJ3L5Y
                    Ants Marching: https://youtu.be/MNgJBIx-hK8
                    Crush: https://youtu.be/elUwSHjfA94
                    
                    More great Singer Songwriter videos here: http://smarturl.it/SingSongwriter?IQi...
                    
                    Follow Dave Matthews Band
                    Website: http://davematthewsband.com/
                    Facebook: https://www.facebook.com/davematthews...
                    Twitter: https://twitter.com/davematthewsbnd
                    
                    Subscribe to Dave Matthews Band on YouTube: http://smarturl.it/DMBSub?IQid=DMBCIM
                    
                    ---------
                    
                    Lyrics:
                    
                    You've got your ball
                    you've got your chain
                    tied to me tight tie me up again
                    who's got their claws
                    in you my friend
                    Into your heart I'll beat again
                    Sweet like candy to my soul
                    Sweet you rock
                    and sweet you roll
                    Lost for you I'm so lost for you 
                    
                    You come crash into me
                    And I come into you
                    I come into you
                    In a boys dream
                    In a boys dream
                    
                    #DaveMatthewsBand #CrashIntoMe #Vevo`,
                    "media": "https://i.ytimg.com/vi/k7in-9E3ImQ/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLD0SYorBmzfwDeXoj9rb-pjIRrBiQ",
                    "link": "https://www.youtube.com/watch?v=k7in-9E3ImQ"
                }
            ]
        }
    },
    'twitter': {
        "nasa" : {
            profile: {
                "username": "nasa",
                "uid": "nasa",
                "source": "twitter",
                "followers": 36500000,
                "link": "https://twitter.com/NASA"
            },
            posts: [
                {
                    "uid": '',
                    "source": "twitter",
                    "title": "Imagine a future where our @NASAPersevere rover found definitive evidence of life on Mars. What would happen next? Studio microphoneIn this new podcast episode of #GravityAssist, our Chief Scientist Jim Green, the Explore Mars Society and others weigh in: https://go.nasa.gov/2SKFKV9",
                    "date": "May 5, 2020",
                    "description": "",
                    "media": "https://pbs.twimg.com/media/EXhwGqIWAAAhb7c?format=jpg&name=medium",
                    "link": "https://twitter.com/NASA/status/1258879356862070788"
                }
            ]
        },
        "KingJames" : {
            profile: {
                "username": "KingJames",
                "uid": "KingJames",
                "source": "twitter",
                "followers": 45900000,
                "link": "https://twitter.com/KingJames"
            },
            posts : [
                {
                    "uid": '',
                    "source": "twitter",
                    "title": "Class of 2020, I told you guys we are working on something special and we have an incredible update…THE GOAT @BarackObama!!! You all worked so hard the past four years and deserve the best commencement we can bring to you and your family. May 16 @GradTogether",
                    "date": "May 5, 2020",
                    "description": "",
                    "media": "https://pbs.twimg.com/media/EXQzOXsUEAIdth-?format=jpg&name=medium",
                    "link": "https://twitter.com/KingJames/status/1257686521471094786"
                }
            ]
        },
        "natgeo" : {
            "profile": {
                "username": "natgeo",
                "uid": "natgeo",
                "source": "twitter",
                "followers": 23900000,
                "link": "https://twitter.com/natgeo"
            },
            "posts" : [
                {
                    "uid": '',
                    "source": "twitter",
                    "title": "A pair of kayakers hold position among swirling eddies in this vibrant long exposure captured by Your Shot photographer Karim Iliya.",
                    "date": "May 9, 2020",
                    "description": "",
                    "media": "https://pbs.twimg.com/media/EXlqv3AXkAEWyl4?format=jpg&name=medium",
                    "link": "https://twitter.com/NatGeo/status/1259154943090610176"
                }
            ]
        },
        "patagonia" : {
            "profile": {
                "username": "patagonia",
                "uid": "patagonia",
                "source": "twitter",
                "followers": 458700,
                "link": "https://twitter.com/patagonia"
            },
            "posts" : [
                {
                    "uid": '',
                    "source": "twitter",
                    "title": "If we’re not going to use our voice, our community, and our resources to deal with one of the greatest crises that we face, then we’re not living up to the mission of the company.",
                    "date": "May 7, 2020",
                    "description": "",
                    "media": "https://www.outsideonline.com/sites/default/files/styles/width_1200/public/2020/05/05/patagonia-columbia-storefronts_h.jpg?itok=4sBjLGUR",
                    "link": "https://twitter.com/patagonia/status/1258520513162252289"
                }
            ]
        },
        "DavidDobrik" : {
            "profile": {
                "username": "DavidDobrik",
                "uid": "DavidDobrik",
                "source": "twitter",
                "followers": 5000000,
                "link": "https://twitter.com/DavidDobrik"
            },
            "posts" : [
                {
                    "uid": '',
                    "source": "twitter",
                    "title": "TWO YEARS IN A ROW!!! So so grateful yall have no idea. Thank you Red heart",
                    "date": "May 2, 2020",
                    "description": "",
                    "media": "https://pbs.twimg.com/media/EXDmRRKUYAA1LQO?format=jpg&name=900x900",
                    "link": "https://twitter.com/DavidDobrik/status/1256757489577504768"
                }
            ]
        },
        "davematthewsbnd" : {
            "profile": {
                "username": "davematthewsbnd",
                "uid": "davematthewsbnd",
                "source": "twitter",
                "followers": 376400,
                "link": "https://twitter.com/davematthewsbnd"
            },
            "posts" : [
                {
                    "uid": '',
                    "source": "twitter",
                    "title": `. 
                    @JeffCoffinMusic
                    is live streaming from his studio this Friday, May 8 @ 7pm Central on his Instagram account. Nashville Bassist/Producer Jon Estes will be his virtual guest. Tune in! Camera with flash 
                    @rene_huemer`,
                    "date": "May 8, 2020",
                    "description": "",
                    "media": "https://pbs.twimg.com/media/EXg3OFbWoAUwlEg?format=jpg&name=900x900",
                    "link": "https://twitter.com/patagonia/status/1258520513162252289"
                }
            ]
        }
    },
    'instagram': {
        "nasa" : {
            profile: {
                "username": "nasa",
                "uid": "nasa",
                "source": "instagram",
                "followers": 57600000,
                "link": "https://instagram.com/nasa"
            },
            posts: [
                {
                    "uid": '',
                    "source": "instagram",
                    "title": "And the land rinsed off its paintbrush into the sea. The Suwannee River in Georgia is known as a “blackwater river” due to its dark-brown waters laden with organic material. Its ink-like waters act like a tracer as it pours into the Gulf of Mexico, revealing where the river water mixes with the sea. Credit: NASA Earth Observatory/ Dr. Alice Alonso",
                    "date": "May 5, 2020",
                    "description": "",
                    "media": "https://scontent-iad3-1.cdninstagram.com/v/t51.2885-15/e35/95496263_2466781163423233_2927173103371924678_n.jpg?_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=1&_nc_ohc=aXrpoRNykpUAX8BNbx4&oh=5d1bfec627ae7ccef21666553333bb05&oe=5EDDB026",
                    "link": "https://www.instagram.com/p/B_5qiAwJqGe/"
                }
            ]
        },
        "lebronjames" : {
            profile: {
                "username": "kingjames",
                "uid": "kingjames",
                "source": "instagram",
                "followers": 64000000,
                "link": "https://instagram.com/kingjames"
            },
            posts: [
                {
                    "uid": '',
                    "source": "instagram",
                    "title": "We’re literally hunted EVERYDAY/EVERYTIME we step foot outside the comfort of our homes! Can’t even go for a damn jog man! Like WTF man are you kidding me?!?!?!?!?!? No man fr ARE YOU KIDDING ME!!!!! I’m sorry Ahmaud(Rest In Paradise) and my prayers and blessings sent to the heavens above to your family!!",
                    "date": "May 7, 2020",
                    "description": "",
                    "media": "https://scontent-iad3-1.cdninstagram.com/v/t51.2885-15/e35/s1080x1080/96673724_1709217632562280_4971074058990452529_n.jpg?_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=1&_nc_ohc=hSfHZWr86VsAX_8ceIP&oh=1c2200f059c4923f13d9476dbee1383a&oe=5EDF0011",
                    "link": "https://www.instagram.com/p/B_3M3wugy38/"
                }
            ]
        },
        "nationalgeographic" : {
            "profile": {
                "username": "natgeo",
                "uid": "natgeo",
                "source": "instagram",
                "followers": 136000000,
                "link": "https://instagram.com/natgeo"
            },
            "posts": [
                {
                    "uid": '',
                    "source": "instagram",
                    "title": "Humpback whales burst through the ocean surface, mouths open in a display of bubble-net feeding in Alaska. Several whales, sometimes as many as 12 or more, make a net of bubbles around herring, corralling them into a tight bait ball, making for more efficient feeding. This strategy, created by humpbacks, is an example of communal feeding, as members of different families work together to feed. Although as many as 2,500 humpbacks can be found in Alaska during summer months, only about 60 engage in this cultural practice. Follow @BrianSkerry for more pictures and insights into the ocean world.",
                    "date": "May 9, 2020",
                    "description": "",
                    "media": "https://scontent-iad3-1.cdninstagram.com/v/t51.2885-15/e15/96389833_1607713489381918_8337918363220592304_n.jpg?_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=1&_nc_ohc=8govq3He4NoAX-8uVu-&oh=90a90ab6409bae681525484c5f208964&oe=5EDF9506",
                    "link": "https://www.instagram.com/p/B_-ICAjgupU/"
                }
            ]
        },
        "patagonia" : {
            "profile": {
                "username": "patagonia",
                "uid": "patagonia",
                "source": "instagram",
                "followers": 4400000,
                "link": "https://instagram.com/patagonia"
            },
            "posts": [
                {
                    "uid": '',
                    "source": "instagram",
                    "title": "Three amazing moms share all the details of what it's like to #vanlife with kids.",
                    "date": "May 8, 2020",
                    "description": "",
                    "media": "https://scontent-iad3-1.cdninstagram.com/v/t51.2885-15/e35/p1080x1080/95948997_239310630479224_5772656621421672185_n.jpg?_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=1&_nc_ohc=G5dRxA5V9U8AX-N0iLP&oh=3e6326a6849c9d7530b4110d89c423ee&oe=5EDEE7CF",
                    "link": "https://www.instagram.com/p/B_8eN8OHNpz/"
                }
            ]
        },
        "daviddobrik" : {
            "profile": {
                "username": "daviddobrik",
                "uid": "daviddobrik",
                "source": "instagram",
                "followers": 12600000,
                "link": "https://instagram.com/daviddobrik"
            },
            "posts": [
                {
                    "uid": '',
                    "source": "instagram",
                    "title": "Cant wait to see you when quarantine is over, but for now our zoom calls will have to do",
                    "date": "May 9, 2020",
                    "description": "",
                    "media": "https://scontent-iad3-1.cdninstagram.com/v/t51.2885-15/e35/p1080x1080/96753366_109786477241993_4987153100210703033_n.jpg?_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=1&_nc_ohc=j5M4QJ1MPrEAX_xvvKn&oh=1443384e514cf30b6b84a6360fcb195f&oe=5EE0B22D",
                    "link": "https://www.instagram.com/p/B_8RqHnAH0z/"
                }
            ]
        },
        "davematthewsband" : {
            "profile": {
                "username": "davematthewsband",
                "uid": "davematthewsband",
                "source": "instagram",
                "followers": 527000,
                "link": "https://www.instagram.com/davematthewsband/"
            },
            "posts": [
                {
                    "uid": '',
                    "source": "instagram",
                    "title": `Are you ready for another round of #DMBDriveIn? The series continues on Wednesday, May 13th at 8pm ET with a full live performance of the @davematthewsband concert at Xfinity Theatre in Hartford, CT on June 22, 2019. The spotlight charity for this episode is @mealsonwheelsamerica.
    
                    DMB Drive-In is presented by @thedreamingtreewines. Visit www.dmbdrivein.com #LinkInBio for more information.`,
                    "date": "May 8, 2020",
                    "description": "",
                    "media": "https://scontent-iad3-1.cdninstagram.com/v/t51.2885-15/e15/96155486_250338459668829_4471300963431982960_n.jpg?_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=i8J_JKuLYeoAX_mvNpX&oh=5ef7f6d986107d1c962637c7ce85e921&oe=5EDF632A",
                    "link": "https://www.instagram.com/p/B_8KwkfJ_WJ/"
                }
            ]
        }
    }
}