import React, { Component, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Input, Form, Checkbox, Card } from 'antd';
import {
    LockOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
// @ts-ignore
import { useNavigate } from "react-router-dom"

interface CustomSignInProps {
    handleSignup: () => void
}

function CustomSignIn(props: CustomSignInProps): JSX.Element {
    
    const navigate = useNavigate();

    const [username, setUsername]: [string, any] = useState('');
    const [password, setPassword]: [string, any] = useState('');

    const signIn = async () => {
        console.log(username, password)
        try {
            const user = await Auth.signIn(username, password);
            props.handleSignup()
            navigate('/')
            return user
        } catch (error) {
            console.log('Error signing in', error);
        }

        return null
    }
  
    const handleSubmit = async () => {
        let user = await signIn();
        console.log(user)        
    }
  
    const handleChange = (e: any) => {
        if (e.target.id === 'username') {
            setUsername(e.target.value)
        } else if (e.target.id === 'password') {
            setPassword(e.target.value)
        }
    }
  
        return (
        <Card title="Sign In">
            <Form
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                >
                <Form.Item
                    rules={[{ required: true, message: 'Please input your Email!' }]}
                >
                    <Input 
                        id='username' 
                        value={username}
                        onChange={handleChange}
                        prefix={<UserOutlined className="site-form-item-icon" />} 
                        placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input
                        id='password' 
                        value={password}
                        onChange={handleChange}
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                    </Button>
                </Form.Item>
                <Form.Item>
                    {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}

                    <a className="login-form-forgot" href="">
                    Forgot password
                    </a>
                </Form.Item>
                <Form.Item>
                    <Link to="/signup">Register Now!</Link>
                </Form.Item>
            </Form>
        </Card>
        );
}

export default CustomSignIn;