import { Button, Modal, Row, Col, Card, Form } from 'antd';
import React, { useState } from 'react';
import {
    sendToApi,
    setModal
} from './insertEntitySlice'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import InsertPage from './InsertEntity';
import PreviewEntity from 'components/EntityPreview/EntityPreview';
import InsertProfileForm from './InsertProfileForm';
// @ts-ignore
import { useNavigate } from "react-router-dom"


const InsertProfileModal: React.FC = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [open, setOpen] : [boolean, any] = useState(false);
    const [preview, setPreview] : [boolean, any] = useState(false)
    const { content, classes, cls, loadingInsert, openModal } = useSelector((state: RootState) => state.insertEntityReducer)
    const { updateModal } = useSelector((state: RootState) => state.updateReducer)

    const [type, setType] = useState(null)
    const [selectType, setSelectType] = useState(true)

    const handleOk = async () => {
         dispatch(sendToApi(content))
    }

    const [form] = Form.useForm();
    
    return <div>
        <Modal
          title="Add Item"
          width="80%"
          visible={openModal}
          footer={[
            selectType ? <Button key="back" onClick={() => dispatch(setModal({modal: false, insert: true}))}>
              Cancel
            </Button> : <Button key="back" onClick={() => setSelectType(true)}>
              Back
            </Button>,
            selectType ? <Button key="next" type="primary" onClick={() => setSelectType(false)}>
              Next
            </Button> : <Button key="submit" loading={loadingInsert} type="primary" onClick={() =>  {
                form.validateFields()
                dispatch(setModal({modal: false, insert: true}))
                navigate("/entity/nasa")
            }}>
              Create
            </Button>
          ]}
          onCancel={() => dispatch(setModal({modal: false, insert: true}))}
        >
            {selectType ? <div>
                <Row gutter={[16, 24]}>
                    <Col span={12}>
                        <Card onClick={() => {
                            setType('Person')
                            setSelectType(false)
                        }} hoverable={true}>
                            Person
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card onClick={() => {
                            setType('Group')
                            setSelectType(false)
                        }} hoverable={true}>
                            Group / Organization
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Card onClick={() => {
                            setType('None')
                            setSelectType(false)
                        }} hoverable={true}>
                            None of the Above
                        </Card>
                    </Col>
                </Row>
            </div> : <Row>
                    <Col span={24}>
                        <InsertProfileForm form={form}/>
                    </Col>
                </Row>}
        </Modal>
    </div>
};

export default InsertProfileModal