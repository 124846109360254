import { Modal, Form, Input } from 'antd';
import React, { useState } from 'react';
import LogRocket from 'logrocket';
import { generateId } from 'utils/dbUtils';

export default function RegisterTesterModal(): JSX.Element {
  
  const [open, setOpen] : [boolean, any] = useState(false)
  const [form] = Form.useForm();

  return (
        <Modal title="Welcome to Agora" visible={open} 
          onCancel={() => {
            setOpen(false)
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                LogRocket.identify(`test_user_${generateId()}`, {
                  name: values.name,
                  email: values.email
                });
                setOpen(false)
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
          >
            <p> Please insert some basic information so we can follow up for feeback :) </p>
            <Form
              form={form}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={console.log}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input a valid email!' }]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
  )
}