import { Spin, Collapse, Col, Row } from 'antd';
import React from 'react';
import { IEntity } from 'agora-types';
import { triage, ParsedEntity } from '../../Triage/triage'
import ProfileFeedItem from '../ProfileFeed/ProfileFeedItem';

interface EntityFeedProps {
    loading: boolean
    entities: IEntity[]
    onSelectEntity: (label: string) => void
}

const { Panel } = Collapse;

const EntityFeed: React.FC<EntityFeedProps> = (props) => {

    const { loading, entities } = props
    const pes: ParsedEntity[] = triage(entities)

    if (loading) {
        return (<div style={{textAlign: 'center'}}> <Spin size="large"/> </div>)
    } else {
        return (
            <div>
            {pes.map((pe: ParsedEntity, i: number) => { 

                // let instanceOfTrip: ITriple = entity.statements.find((trip : ITriple) => trip.predicate.value === 'https://agora-data.com/Property/instanceOf')
                let classLabel: string = pe.instance.object.value.split('/').reverse()[0]
                // let subjectURI: URI = instanceOfTrip.subject
                
                return(
                    <Row key={i} gutter={[16, 24]}>
                        <Col span={24}>
                            <ProfileFeedItem entity={pe} onSelect={() => props.onSelectEntity(pe.label.object.value.toLowerCase().replace(" ", ""))}/>
                        </Col>
                    </Row>
                )}
                )}
            </div>
        
        );
    }
};

export default EntityFeed