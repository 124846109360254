import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import IAgoraPost from 'external_sources/IAgoraPost'
import IAgoraExProfile from 'external_sources/IAgoraExProfile'
import ExternalSourceController from 'external_sources/ExternalSourceController'

const externalSourceControl = new ExternalSourceController()

interface EntityState {
    label: string,
    description: string,
    instance: string,
    profiles: {
        [source: string]: IAgoraExProfile
    }
    loading: boolean,
    hasProfile: boolean,
    posts: IAgoraPost[]
}

const initialState: EntityState = {
    label: "",
    description: "",
    instance: 'Group / Organization',
    profiles: {},
    loading: false,
    hasProfile: true,
    posts: []
}

const entitiyDetails = createSlice({
  name: 'entitiyDetails',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
        state.loading = action.payload
    },
    setDescription(state, action: PayloadAction<string>) {
        state.description = action.payload
    },
    setHasProfile(state, action: PayloadAction<boolean>) {
        state.hasProfile = action.payload
    },
    setProfile(state, action: PayloadAction<IAgoraExProfile>) {
        const profile = action.payload
        state.profiles[profile.source] = profile
    },
    clearProfiles(state, action: PayloadAction) {
        state.profiles = {}
    },
    setLabel(state, action: PayloadAction<string>) {
        state.label = action.payload
    },
    setInstance(state, action: PayloadAction<string>) {
        state.instance = action.payload
    },
    setPosts(state, action: PayloadAction<IAgoraPost[]>) {
        state.posts = action.payload
    }
  }
})

export const {
    setLoading,
    setLabel,
    setDescription,
    setInstance,
    setPosts,
    setProfile,
    setHasProfile,
    clearProfiles
} = entitiyDetails.actions

export default entitiyDetails.reducer

export const fetchPostsAndProfiles = (profiles: {
    [source: string]: string,
}): AppThunk => async (dispatch, getState) => {

    dispatch(setLoading(true))
    dispatch(setPosts([]))
    dispatch(clearProfiles())

    let new_profiles: IAgoraExProfile[] = []
    await Promise.all(Object.keys(profiles).map(async (source: string) => {
        let profile = await externalSourceControl.fetchProfile(source, profiles[source])
        new_profiles.push(profile)
        if (profile)
            dispatch(setProfile(profile))
    }));

    let allPosts: IAgoraPost[] = []

    await Promise.all(new_profiles.map(async (profile: IAgoraExProfile) => {
        if (profile) {
            let posts: IAgoraPost[] = await externalSourceControl.fetchPosts(profile)
            allPosts = posts.concat(allPosts);
        }
    }));

    dispatch(setPosts(allPosts))
    dispatch(setLoading(false))
}

export const fetchPosts = (): AppThunk => async (dispatch, getState) => {

    dispatch(setLoading(true))

    const { profiles } = getState().entityReducer
    // const profiles: IAgoraExProfile[] = [reddit, instagram, twitter, youtube];
    let allPosts: IAgoraPost[] = []

    await Promise.all(Object.values(profiles).map(async (profile: IAgoraExProfile) => {
        if (profile) {
            let posts: IAgoraPost[] = await externalSourceControl.fetchPosts(profile)
            allPosts = posts.concat(allPosts);
        }
    }));

    dispatch(setPosts(allPosts))
    dispatch(setLoading(false))
}

export const fetchProfiles = (profiles: {
    [source: string]: string,
}): AppThunk => async (dispatch, getState) => {

    dispatch(setLoading(true))

    await Promise.all(Object.keys(profiles).map(async (source: string) => {
        let profile = await externalSourceControl.fetchProfile(source, profiles[source])
        if (profile)
            dispatch(setProfile(profile))
    }));

    dispatch(setLoading(false))
}

