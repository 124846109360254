import { Layout, Row, Col, Menu, Card, Avatar, PageHeader, List, Statistic, Spin, Button, Tag, Checkbox, Empty, Tabs } from 'antd';
import React, {useEffect} from 'react';
import SidebarFilter from 'containers/Home/SidebarFilter';
import Discovery from 'containers/Home/Discovery';
import { useParams } from 'react-router-dom';
import { FaReddit, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'
import {
    TwitterOutlined,
    YoutubeOutlined,
    FacebookOutlined,
    InstagramOutlined,
    RedditOutlined
  } from '@ant-design/icons';

// @ts-ignore
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import IAgoraExProfile from 'external_sources/IAgoraExProfile';
import { fetchPosts, fetchProfiles, setLabel, setDescription, setInstance, setHasProfile, fetchPostsAndProfiles } from './profileSlice';
import IAgoraPost from 'external_sources/IAgoraPost';
import ReactDOM from 'react-dom';
import { toTitleCase } from 'utils/stringUtils';
import { setModal } from 'containers/InsertEntity/insertEntitySlice';
import { AgoraData } from './data'
import AgoraPost from './AgoraPost';
import ExternalSourceController from 'external_sources/ExternalSourceController';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import NewsFeed from './NewsFeed';
import Overview from './Overview';

const { Content, Sider } = Layout;
const { TabPane } = Tabs;
const { Meta } = Card;

const externalSourceController = new ExternalSourceController()

export default function Entity() {

    let { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { profiles, label, instance, description, loading, posts, hasProfile } = useSelector((state: RootState) => state.entityReducer)

    const sortedPosts = posts.slice().sort((a: IAgoraPost, b: IAgoraPost) => {

        let an: number = new Date(a.date).getTime()
        let bn: number = new Date(b.date).getTime()

        if (isNaN(an))
            an = new Date(parseInt(a.date)).getTime()
        if (isNaN(bn))
            bn = new Date(parseInt(b.date)).getTime()

        return bn - an
    })

    useEffect(() => {
        const load = async () => {
            console.log('loading new profiles')
            if (AgoraData[id.toLowerCase()]) {
                dispatch(setLabel(AgoraData[id.toLowerCase()].label))
                dispatch(setDescription(AgoraData[id.toLowerCase()].description))
                dispatch(setInstance(AgoraData[id.toLowerCase()].instance))

                const accounts: {
                    reddit: string,
                    instagram: string,
                    twitter: string,
                    youtube: string
                } = {
                    reddit: '',
                    instagram: AgoraData[id.toLowerCase()].instagram,
                    twitter: AgoraData[id.toLowerCase()].twitter,
                    youtube: AgoraData[id.toLowerCase()].youtube
                }

                dispatch(fetchPostsAndProfiles(accounts))
            } else {
                dispatch(setHasProfile(false))
            }
        }

        load()
    }, [id])

    // useEffect(() => {
    //     dispatch(fetchPosts())
    // }, [reddit, twitter, youtube, instagram])

    // const profiles: IAgoraExProfile[] = [reddit, instagram, twitter, youtube].filter((value: IAgoraExProfile) => value !== null)

    const getIcon = (source: string, link: string, size: number) : JSX.Element => {
        let icon: JSX.Element = null

        switch (source) {
            case 'reddit':
                icon = <Tag icon={<RedditOutlined />} color="#FF4301">
                <a href={link} target="_blank"> Reddit </a> 
            </Tag>
                break
            case 'instagram':
                icon = <Tag icon={<InstagramOutlined />} color="#F56040">
                <a href={link} target="_blank"> Instagram </a> 
            </Tag>
                break
            case 'twitter':
                icon = <Tag icon={<TwitterOutlined />} color="#55acee">
                    <a href={link} target="_blank"> Twitter </a> 
                </Tag>
                break
            case 'youtube':
                icon = <Tag icon={<YoutubeOutlined />} color="#cd201f">
                <a href={link} target="_blank"> Youtube </a> 
            </Tag>
                break
        }
        return icon
    }

    const getSimpleIcon = (source: string, size: number) : JSX.Element => {
        let icon: JSX.Element = null

        switch (source) {
            case 'reddit':
                icon = <FaReddit size={`${size}em`}/>
                break
            case 'instagram':
                icon = <FaInstagram size={`${size}em`}/>
                break
            case 'twitter':
                icon = <FaTwitter size={`${size}em`}/>
                break
            case 'youtube':
                icon = <FaYoutube size={`${size}em`}/>
                break
        }

        return icon
    }


    if (hasProfile) {
        return (
        <Layout style={{ padding: '24px' }}>
            <Content
            className="site-layout-background"
            style={{
                margin: 0,
                height: 'inherit',
                minHeight: 6000,
                background: '#fff'
            }}
            >
            
                <PageHeader
                    className="site-page-header"
                    onBack={() => navigate('/')}
                    title={label}
                    subTitle={<Tag color="blue"> {instance} </Tag>}
                    style={{'border': '1px solid rgb(235, 237, 240)', paddingBottom: 20}}
                    extra={[
                        <Spin key='1' spinning={loading}/>,
                        <Button key="2" onClick={() => dispatch(fetchPosts())}> Refresh Posts </Button>
                    ]}
                />

                <Row style={{"padding": "10px"}}>
                    {description}
                </Row>


                <Row>
                    {Object.values(profiles).map((profile: IAgoraExProfile, i: number) => 
                    <Col key={i} flex={1}>
                        
                    <Card loading={loading} key={i} hoverable={true}>
                        
                            <Meta
                                avatar={profile ?
                                    getIcon(profile.source, profile.link, 1) : null
                                }
                                title={profile ? <a href={profile.link} target="_blank"> {profile.username} </a> : ''}
                            /> 

                            <Statistic title="Followers" value={profile ? profile.followers : ''} />
                        </Card>
                    </Col>)}
                </Row>

                <Tabs defaultActiveKey="1" onChange={console.log}>
                    <TabPane tab="Overview" key="0">
                        <Overview />
                    </TabPane>
                    <TabPane tab="News" key="1">
                        <NewsFeed />
                    </TabPane>
                    <TabPane tab="Social" key="2">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Grid container direction="column" justify="center" alignItems="center" spacing={4}>
                                    {sortedPosts.map((post: IAgoraPost, i: number) => 
                                            <Grid item key={i}>
                                                <Card
                                                bordered={false}
                                                cover={externalSourceController.fetchEmbeddedPost(post)}>
                                                    <Card.Meta title={getIcon(post.source, post.link, 0)} description={isNaN(new Date(post.date).getTime()) ? new Date(parseInt(post.date)).toLocaleString() : new Date(post.date).toLocaleString()}/>
                                                </Card>
                                            </Grid>
                                        )}
                                    
                                </Grid>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Content>
        </Layout>
       )
    } else {
        return (
            <Layout style={{ padding: '24px' }}>
            <Content
            className="site-layout-background"
            style={{
                margin: 0,
                minHeight: 600,
                background: '#fff'
            }}
            >
            
                <PageHeader
                    className="site-page-header"
                    onBack={() => navigate('/')}
                    title={toTitleCase(id)}
                    subTitle={"Cannot find profile"}
                    style={{'border': '1px solid rgb(235, 237, 240)', paddingBottom: 20}}
                />

            <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
            height: 60,
            }}
            description={
                <span>
                    Cannot find a profile for user: {id}
                </span>
            }
        >
            <Button type="primary" onClick={() => dispatch(setModal({modal: true, insert: true}))}>Create Now</Button>
        </Empty>
        </Content>
    </Layout>)
    }
}