import { Layout, Row, Col, Menu, Card, Avatar, PageHeader, List, Statistic, Spin, Button, Tag, Checkbox, Empty, Tabs } from 'antd';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { AgoraData } from './data'


export default function NewsFeed() {

    let { id } = useParams();
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(true)
    console.log(id, AgoraData[id].newsEndpoint)

    useEffect(() => {
        const loadNews = async () => {
            let response = await fetch(AgoraData[id].newsEndpoint)
            let body = await response.json()
            setNews(body.articles)
            setLoading(false)
        }

        loadNews()
    }, [])

    return (
        <List
            itemLayout="vertical"
            size="large"
            loading={loading}
            dataSource={news}
            renderItem={item => {
            console.log(item)

        return (
                <List.Item
                    key={item.title}
                    extra={
                    item.socialimage ? 
                    <a href={item.url} target="_blank">
                        <img
                            width={272}
                            alt="logo"
                            src={item.socialimage}
                        />
                    </a> : null
                    }
                >
                    <List.Item.Meta
                    title={<a target="_blank" href={item.url}>{item.title}</a>}
                    description={<a target="_blank" href={item.domain}>{item.domain}</a>}
                    />
                </List.Item>
            )
    }}/>)
}