import { IEntity, ITriple, URI } from "agora-types";
import config from './triageConfig.json'

export interface ParsedEntity {
    networkTriples: ITriple[]
    mapTriples: ITriple[]
    timelineTriples: ITriple[]
    otherTriples: ITriple[]
    description: ITriple
    label: ITriple
    instance: ITriple
    uri: URI
}

export function triage( entities: IEntity[] ): ParsedEntity[] {

    let pes: ParsedEntity[] = []

    entities.forEach((ie: IEntity) => {
        let parseE: ParsedEntity = {
            networkTriples: [],
            mapTriples: [],
            timelineTriples: [],
            otherTriples: [],
            description: null,
            label: null,
            instance: null,
            uri: null
        }

        // Filter Based on Predicate
        ie.statements.forEach((it: ITriple) => {
            if (config.label.includes(it.predicate.value)) {
                parseE.label = it
                parseE.uri = it.subject
            } else if (config.description.includes(it.predicate.value)) {
                parseE.description = it
            } else if (config.instance.includes(it.predicate.value)) {
                parseE.instance = it
            }
        })

        // Filter Based on Object
        ie.statements.forEach((it: ITriple) => {
            if (it.object.kind === 'uri') {
                parseE.networkTriples.push(it)
            } else if (it.object.kind === 'literal') {
                if (config.timeline.includes(it.object.dataType.value)) {
                    parseE.timelineTriples.push(it)
                } else if (config.map.includes(it.object.dataType.value)) {
                    parseE.mapTriples.push(it)
                } else {
                    parseE.otherTriples.push(it)
                }
            }
        })

        pes.push(parseE)
    })

	return pes
}