import { Menu, notification, Layout } from 'antd';
import SearchBox from 'containers/Search/SearchBox';
import React from 'react';
import {
    UserOutlined
} from '@ant-design/icons';
// @ts-ignore
import { useNavigate } from "react-router-dom"

interface NavBarProps {
    theme: 'dark' | 'light'
    user: any
    handleLogout: () => void
}

const { Header, Content, Sider } = Layout;

export default function NavBar(props: NavBarProps) {
    const navigate = useNavigate();
    const { user, handleLogout } = props

    return (
        <Header className="header">
        <div 
        className="logo"
        onClick={() => navigate('/')}
        // style={{
        //     'cursor': 'pointer',
        //   'width': '120px',
        //   'height': '31px',
        //   'color': 'white',
        //   'background': 'rgba(255, 255, 255, 0.2)',
        //   'margin': '16px 28px 7px 0',
        //   'textAlign': 'center',
        //   'float': 'left',
        //   'fontSize': 'x-large'
        // }}
        > 
        {/* <p
        style={{
                'lineHeight': '0',
                'display': 'inline-block',
                'verticalAlign': 'middle'
        }}
        > Agora </p>  */}
        </div>
            <Menu
                theme={props.theme}
                mode="horizontal"
                style={{ lineHeight: '64px' }}
            >
                {/* <Menu.Item disabled key="1">
                    <SearchBox onSelect={console.log}/>
                </Menu.Item>

                <Menu.Item onClick={async () => navigate('/entity/test')}> Entity </Menu.Item>  */}

                {/* {user ? 
                    <Menu.Item style={{float: 'right'}} onClick={async () => await handleLogout()}> Logout </Menu.Item> 
                : <Menu.Item style={{float: 'right'}} onClick={() => navigate('/login')}> Login </Menu.Item>}
                {user ? 
                    <Menu.Item style={{float: 'right'}} onClick={() => navigate('/')}> <UserOutlined /> </Menu.Item> 
                : <Menu.Item style={{float: 'right'}} onClick={() => navigate('/signup')}> SignUp </Menu.Item>} */}
            </Menu>
        </Header>
       )
}