import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IClass, Literal, ITriple, URI, IProp, MultiProp, SingleProp, Shape, IEntity, ITripleElement } from 'agora-types'
import { AppThunk } from 'app/store'
import { updateTriple, insert, getClassShape } from '../../api/graphAPI'
import { loadEntities } from 'containers/Home/discoverySlice'
import dateFormat from 'dateformat';
import { clsFromShape } from '../InsertEntity/insertEntitySlice'
import Prop from 'agora-types/interfaces/IProp'

interface UpdateState {
    newTriple: ITriple
    addLoading: boolean
    addModal: boolean
    addError: string
    cls: IClass
    prop: SingleProp
    subject: URI
}

const initialState: UpdateState = {
    newTriple: null,
    addLoading: false,
    addModal: false,
    addError: '',
    cls: null,
    prop: null,
    subject: null
}


const insertDetails = createSlice({
  name: 'insertDetails',
  initialState,
  reducers: {
    setAddLoading(state, action: PayloadAction<boolean>) {
        state.addLoading = action.payload
    },
    setNewTriple(state, action: PayloadAction<ITriple>) {
        state.newTriple = action.payload
    },
    setAddModal(state, action: PayloadAction<boolean>) {
        state.addModal = action.payload
    },
    setAddError(state, action: PayloadAction<string>) {
        state.addError = action.payload
    },
    setClass(state, action: PayloadAction<IClass>) {
        state.cls = action.payload
    },
    setProp(state, action: PayloadAction<SingleProp>) {
        state.prop = action.payload
    },
    setSubject(state, action: PayloadAction<URI>) {
        state.subject = action.payload
    },
    reset(state, action: PayloadAction) {
        return initialState
    }
  }
})

export const {
    setAddLoading, 
    setAddError,
    setAddModal,
    setNewTriple,
    setClass,
    setProp,
    setSubject,
    reset
} = insertDetails.actions

export default insertDetails.reducer

export const addTriple = (subjectURI: URI, clsLabel: string): AppThunk => async dispatch => {

    dispatch(setAddModal(true))
    dispatch(setAddLoading(true))
    dispatch(setSubject(subjectURI))

    try {
        let shape : Shape = await getClassShape(clsLabel)

        if (shape) {
            let cls: IClass = clsFromShape(shape, clsLabel)

            // ADD SUPPORT FOR MULTIPROPS
            let multiPropOptions: SingleProp[] = []
            cls.requiredProperties.concat(cls.suggestedProperties).forEach((p: IProp) => {
                if (p.kind === 'multiprop') {
                    Object.values(p.options).forEach((sp: SingleProp) => multiPropOptions.push(sp))
                }
            })
            
            cls.requiredProperties = cls.requiredProperties.filter((value: IProp) => value.kind === 'singleprop' && value.URI !== 'https://agora-data.com/Property/dateEntered')
            cls.suggestedProperties = cls.suggestedProperties.filter((value: IProp) => value.kind === 'singleprop')
            cls.suggestedProperties = cls.suggestedProperties.concat(multiPropOptions)
            dispatch(setClass(cls))
            let prop: SingleProp = cls.requiredProperties.concat(cls.suggestedProperties).find((value: IProp) => value.kind === 'singleprop') as SingleProp
            dispatch(setProp(prop))
        }
    } catch(e) {
        console.log(e)
    }

    dispatch(setAddLoading(false))

}

export const submitNewTriple = (newTriple: ITriple): AppThunk => async dispatch => {
    
    let error: string = validate(newTriple)

    if (error !== '') {
        dispatch(setAddError(error))
    } else {
        dispatch(setAddLoading(true))
        try {
            let response: number = await insert([newTriple])
            if (response) {
                dispatch(loadEntities())
                dispatch(reset())
            }
        } catch (e) {
            console.log("SUBMIT NEW TRIPLE ERROR: ", e)
        }
    }
}

const validate = (newTriple: ITriple): string => {
    if (newTriple.object.value === '') {
        return 'New value cannot be empty.'
    }

    return ''
}