import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IClass, Literal, ITriple, URI, IProp, MultiProp, SingleProp, Shape, IEntity, ITripleElement } from 'agora-types'
import { AppThunk } from 'app/store'
import { getClasses, getClassShape, insert } from '../../api/graphAPI'
import { loadEntities } from 'containers/Home/discoverySlice'
import dateFormat from 'dateformat';

interface InsertState {
    content: {
        [sid: string] : {
            triples: {
                [pid: string]: {
                    [tid: string]:  ITriple,
                }
            },
            class: IClass
        }
    },
    errors: {
        [sid: string] : {
            [pid: string]: string
        }
    },
    classes: IClass[],
    cls: IClass,
    loading: boolean,
    loadingInsert: boolean,
    openModal: boolean,
    insert: boolean
}

const initialState: InsertState = {
  content: {},
  errors: {},
  classes: [],
  cls: null,
  loading: true,
  loadingInsert: false,
  openModal: false,
  insert: true
}


const insertDetails = createSlice({
  name: 'insertDetails',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
        state.loading = action.payload
    },
    setLoadingInsert(state, action: PayloadAction<boolean>) {
        state.loadingInsert = action.payload
    },
    setModal(state, action: PayloadAction<{ modal: boolean, insert: boolean}>) {
        state.openModal = action.payload.modal
    },
    setClasses(state, action: PayloadAction<IClass[]>) {
        state.classes = action.payload
        if (action.payload.length > 0)
            state.cls = action.payload[0]
    },
    setClass(state, action: PayloadAction<{ sid: string, cls: IClass}>) {
        const { sid, cls } = action.payload
        state.cls = cls
    },
    setDefaultContent(state, action: PayloadAction<{ sid: string, cls: IClass}>) {
        const { sid, cls } = action.payload
        if (cls.requiredProperties) {

            let newContent: {
                [sid: string] : {
                triples: {
                        [pid: string]: {
                            [tid: string]: ITriple
                        } 
                    }
                    class: IClass
                }
            } = {
                [sid]: {
                    triples: {},
                    class: cls
                }
            }

            const defaultTrips: ITriple[] = defaultTriples(sid, cls.uri)

            defaultTrips.forEach((trip: ITriple, index: number) => {
                newContent[sid].triples[trip.predicate.value] = { [(-1 * (index + 1)).toString()] : trip }
            });

            cls.requiredProperties.concat(cls.suggestedProperties || []).forEach((prop: IProp) => {

                if (prop.label !== 'date entered') {
            
                    let tid: string;

                    if (prop.URI in newContent[sid].triples) {
                        tid = Object.keys(newContent[sid].triples[prop.URI]).length.toString()
                    } else {
                        newContent[sid].triples[prop.URI] = {}
                        tid = '0'
                    }

                    let predicate: URI;
                    let object: ITripleElement;
                    if (prop.kind == 'singleprop') {

                        predicate = {
                            value: prop.URI,
                            label: prop.label,
                            kind: 'uri'
                        }

                        object = {
                            value: '',
                            dataType: {
                                value: prop.dataTypeRange.concat(prop.entityRange)[0].value,
                                label: prop.dataTypeRange.concat(prop.entityRange)[0].label,
                                kind: 'uri'
                            },
                            kind: 'literal'
                        }

                    } else {

                        let options: SingleProp[] = Object.values(prop.options)


                        predicate = {
                            value: options[0].URI,
                            label: options[0].label,
                            kind: 'uri'
                        }

                        object = {
                            value: '',
                            dataType: {
                                value: options[0].dataTypeRange.concat(options[0].entityRange)[0].value,
                                label: options[0].dataTypeRange.concat(options[0].entityRange)[0].label,
                                kind: 'uri'
                            },
                            kind: 'literal'
                        }


                    }

                    let triple: ITriple = {
                        subject: {
                            value: '',
                            kind: 'uri'
                        }, 
                        predicate: predicate, 
                        object: object
                    }

                    newContent[sid].triples[predicate.value] = { [tid] : triple }
                }
            })

            state.content = newContent
        }
    },
    addTriple(state, action: PayloadAction<{sid: string, prop: SingleProp}>) {
        const { sid, prop } = action.payload
        const pid : string = prop.URI

        let n_tid: number
        if (state.content[sid].triples[pid]) {
            n_tid = Object.keys(state.content[sid].triples[pid]).length
            while (n_tid.toString() in state.content[sid].triples[pid]) {
                n_tid++
            }
        }
        else
            n_tid = 0

        

        let tid: string = n_tid.toString()

        let triple : ITriple = {
            subject: {
                value: `https://seequence.io/entity/${sid}`,
                kind: 'uri'
            }, 
            predicate: {
                value: prop.URI,
                kind: 'uri'
            },
            object: {
                value: '',
                dataType: {
                    value: prop.dataTypeRange.concat(prop.entityRange)[0].value,
                    label: prop.dataTypeRange.concat(prop.entityRange)[0].label,
                    kind: 'uri'
                },
                kind: 'literal'
            }
        }

        if (pid in state.content[sid].triples) {
            state.content[sid].triples[pid][tid] = triple
        } else {
            state.content[sid].triples[pid] = { [tid]: triple }
        }
    },
    updateTriple(state, action: PayloadAction<{sid: string, tid: string, triple: ITriple}>) {
        const { sid, tid, triple } = action.payload
        let pid : string = triple.predicate.value

        if (pid in state.content[sid].triples) {
            state.content[sid].triples[pid][tid] = triple
        } else {
            state.content[sid].triples[pid] = { [tid]: triple }
        }
    },
    deleteTriple(state, action: PayloadAction<{sid: string, pid: string, tid: string}>) {
        
        const { sid, pid, tid } = action.payload

        if (tid in state.content[sid].triples[pid]) {
            delete state.content[sid].triples[pid][tid]
        }
    },
    deleteEntity(state, action: PayloadAction<{sid: number}>) {
       
        console.log('not deleting entity')
    },
    addEntity(state, action: PayloadAction<{cls: IClass}>) {
        console.log('not adding entity')
    },
    reset(state, action: PayloadAction<{}>) {
        return initialState
    },
    setErrors(state, action: PayloadAction<{
        [sid: string] : {
            [pid: string]: string
        }
    }>) {
        state.errors = action.payload
    }
  }
})

export const {
    addTriple,
    updateTriple,
    deleteTriple,
    deleteEntity,
    addEntity,
    reset,
    setClasses,
    setClass,
    setDefaultContent,
    setLoading,
    setLoadingInsert,
    setModal,
    setErrors
} = insertDetails.actions

export default insertDetails.reducer

const defaultTriples = (sid: string, clsUri: string): ITriple[] => {

    let newItemTypeTriple : ITriple = {
        subject: {
            value: sid,
            kind: 'uri'
        }, 
        predicate: {
            value: `https://agora-data.com/Property/instanceOf`,
            kind: 'uri',
            label: 'instanceOf'
        },
        object: {
            value: clsUri,
            kind: 'uri',
        }
    }

    let dateEnteredTriple : ITriple = {
        subject: {
            value: sid,
            kind: 'uri'
        }, 
        predicate: {
            value: `https://agora-data.com/Property/dateEntered`,
            kind: 'uri',
            label: `date entered`
        },
        object: {
            value: dateFormat(new Date(), `yyyy-mm-dd'T'HH:MM:ss`),
            dataType: {
                value: `http://www.w3.org/2001/XMLSchema#dateTime`,
                kind: 'uri'
            },
            kind: 'literal',
        }
    }
        
    return [
        newItemTypeTriple,
        dateEnteredTriple
    ]
}

export const clsFromShape = (shape: Shape, classLabel: string) => {
    
    let cls: IClass = {
        uri: `https://agora-data.com/Class/${classLabel}`,
        label: shape.label,
        description: shape.description
    }

    let reqProps : IProp[] = []
    let suggProps : IProp[] = []

    shape.properties.forEach((prop: IProp) => {
        if (prop.required) {
            reqProps.push(prop)
        } else {
            suggProps.push(prop)
        }
    })

    cls.requiredProperties = reqProps
    cls.suggestedProperties = suggProps
    return cls
}

export const loadItemClasses = (sid: string): AppThunk => async dispatch => {
    dispatch(setLoading(true))

    try {
        const classes: IClass[] = await getClasses("Item")
        dispatch(setClasses(classes))

        let shape : Shape = await getClassShape(classes[0].label)

        if (shape) {
            let cls: IClass = clsFromShape(shape, classes[0].label)
            dispatch(setClass({ sid: sid, cls: cls}))
            dispatch(setDefaultContent({ sid: sid, cls: cls}))
        }

        dispatch(setLoading(false))

    } catch (err) {
        console.log(err)
    }

}

export const loadClassShape = (sid: string, cls: IClass): AppThunk => async dispatch => {
    try {
        let shape : any = await getClassShape(cls.label)

        if (shape) {
            cls = clsFromShape(shape, cls.label)
            dispatch(setClass({ sid: sid, cls: cls}))
            dispatch(setDefaultContent({ sid: sid, cls: cls}))
        }

    } catch (err) {
        console.log(err)
    }
}

export const sendToApi = (content: {
    [sid: string] : {
    triples: {
            [pid: string]: {
                [tid: string]: ITriple
            } 
        }
        class: IClass
    }
}): AppThunk => async dispatch => {

    let errors: {
        [sid: string] : {
            [pid: string]: string
        }
    } = validate(content)
    
    if (Object.keys(errors).length > 0) {
        dispatch(setErrors(errors))
    } else {
        dispatch(setLoadingInsert(true))
        let apiTriples : ITriple[] = []

            Object.values(content).forEach((value: {
                triples: {
                    [pid: string]: {
                        [tid: string]: ITriple
                    }
                }
                class: IClass
            }) => {
                Object.values(value.triples).forEach((trips: { [tid: string]: ITriple }) => {
                    Object.values(trips).forEach((trip : ITriple) => {
                        if (trip.object.value !== '')
                            apiTriples.push(trip)
                    })
                })
            })

            console.log(apiTriples)

            try {
                let response: number = await insert(apiTriples)
                // let response: number = 1
                if (response) {
                    dispatch(setLoadingInsert(false))
                    dispatch(setModal({modal: false, insert: true}))
                    dispatch(reset({}))
                    dispatch(loadEntities())
                }
            } catch (e) {
                dispatch(setLoadingInsert(false))
                console.log("INSERT ERROR", e)
            }
        }
}



const validate = (content: {
    [sid: string] : {
    triples: {
            [pid: string]: {
                [tid: string]: ITriple
            } 
        }
        class: IClass
    }
}): {
    [sid: string] : {
        [pid: string]: string
    }
} => {
    let errors : any = {}
    Object.keys(content).forEach((sid: string) => {
        let errorObj = validateEntity(content[sid].class, content[sid].triples)
        if (Object.keys(errorObj).length !== 0) {
            errors[sid] = errorObj
        }
    })
    return errors
}

const validateEntity = (cls : IClass, triples: { [pid: string] : { [tid: string] : ITriple } }): {
    [pid: string]: string
} => {
    let errorObj : { [uri: string] : string } = {}

    cls.requiredProperties.forEach((prop : IProp) => {
        let required_prop_triples: { [tid: string] : ITriple } = triples[prop.URI]

        let find: boolean = Object.values(required_prop_triples).some((trip: ITriple) => trip.object.value !== '')
        
        if (!find) {
            errorObj[prop.URI] = "Required"
        }
    })

    return errorObj
}