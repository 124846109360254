import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { Auth } from 'aws-amplify';
import { setErrors } from 'containers/InsertEntity/insertEntitySlice';

interface RootState {
  user: string,
  error: string
}

// The initial state of our store when the app loads.
let initialState: RootState = {
    user: null,
    error: ''
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<string>) {
        state.user = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    }
  }
})

export const {
  setUser
} = userSlice.actions

export default userSlice.reducer

export const updateUser = (): AppThunk => async (dispatch, getState) => {
  try {
    let user = await Auth.currentAuthenticatedUser()
    dispatch(setUser(JSON.stringify(user)))
  } catch (err) {
    dispatch(setUser(null))
    dispatch(setErrors(err))
  }
}

export const logout = (): AppThunk => async (dispatch, getState) => {
  console.log("handling logout")
  try {
    await Auth.signOut({ global: true })
    try {
      let user = await Auth.currentAuthenticatedUser()
      dispatch(setUser(JSON.stringify(user)))
    } catch {
      dispatch(setUser(null))
    }
  } catch (err) {
    dispatch(setErrors(err))
    console.log(err)
  }
};