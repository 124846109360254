import { Shape, IClass, IProp } from "agora-types";

const shapes: { [id: string]: Shape } = {
    'Person' : {
        label: 'Person',
        description: 'A Person',
        properties: []
    },
    'Group / Organization' : {
        label: 'Group / Organization',
        description: 'A Group or Organization',
        properties: []
    }
}

const sources = ["Twitter", "Instagram", "Youtube", "Reddit", "Wikipedia"]
const properties: IProp[] = sources.map((s: string) => {
    return {
        label: s,
        URI: `https://agora-data.com/${s}`,
        description: s,
        unique: true,
        required: false,
        kind: 'singleprop',
        dataTypeRange: [],
        entityRange: []
    }
})

const classes: IClass[] = [
    {
        label: 'Person',
        uri: 'https://agora-data.com/Person',
        description: 'A Person'
    },
    {
        label: 'Group / Organization',
        uri: 'https://agora-data.com/Group',
        description: 'A Group or Oranization'
    }
]

export { shapes, classes, properties };