import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { URI, IEntity } from 'agora-types'
import { AppThunk } from 'app/store'
import { getEntities, deleteEntity } from '../../api/graphAPI'

interface DiscoveryState {
    entities: IEntity[]
    classFilter: String[]
    loading: boolean
    showDetails: boolean
    selected: number
}

const initialState: DiscoveryState = {
    entities: [],
    classFilter: ["Person", "Group"],
    // classFilter: ["Person", "Place", "Event", "Thing"],
    loading: true,
    showDetails: false,
    selected: -1
}

const discoveryDetails = createSlice({
  name: 'discoveryDetails',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
        state.loading = action.payload
    },
    setShowDetails(state, action: PayloadAction<boolean>) {
        state.showDetails = action.payload
    },
    setSelected(state, action: PayloadAction<number>) {
        state.selected = action.payload
    },
    setClassFilter(state, action: PayloadAction<String[]>) {
        state.classFilter = action.payload
    },
    setEntities(state, action: PayloadAction<IEntity[]>) {
        state.entities = action.payload
    },
  }
})

export const {
    setLoading,
    setEntities,
    setClassFilter,
    setShowDetails,
    setSelected
} = discoveryDetails.actions

export default discoveryDetails.reducer

export const loadEntities = (): AppThunk => async (dispatch, getState) => {

    dispatch(setLoading(true))

    try {
        const entities: IEntity[] = await loadEntitiesWithFilter(getState().discoveryReducer.classFilter)
        dispatch(setEntities(entities))
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err)
    }
}

export const removeEntity = (subjectUri: URI): AppThunk => async (dispatch, getState) => {
    
    dispatch(setLoading(true))

    try {
        const response: any = await deleteEntity(subjectUri)
        let entities: IEntity[] = await loadEntitiesWithFilter(getState().discoveryReducer.classFilter)
        dispatch(setEntities(entities))
        dispatch(setLoading(false))

    } catch (err) {
        console.log(err)
    }
}

export const onClassFilterChange = (classFilters: String[]): AppThunk => async dispatch => {
    dispatch(setClassFilter(classFilters))
    dispatch(setLoading(true))
    let entities: IEntity[] = await loadEntitiesWithFilter(classFilters)
    dispatch(setEntities(entities))
    dispatch(setLoading(false))
}

const loadEntitiesWithFilter = async (classFilters: String[]) : Promise<IEntity[]> => {
    let entities: IEntity[] = []

    if (classFilters.length > 0) {

        await Promise.all(classFilters.map(async (cls: String) => {
            let rsp: IEntity[] = await getEntities(cls)
            entities = entities.concat(rsp)
        }));

    } else {
        entities = await getEntities()
    }

    return entities
}