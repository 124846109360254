import React, { useEffect } from 'react';
import CustomSignIn from '../auth/CustomSignIn'
import CustomSignUp from '../auth/CustomSignUp'
import NavBar from 'components/Navigation/NavBar';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom"
// @ts-ignore
import { Routes } from "react-router-dom"
import ProfileHome from '../containers/Home/ProfileHome'
import Entity from 'containers/Profile/Profile';
import InsertProfileModal from 'containers/InsertEntity/InsertProfileModal';
import RegisterTesterModal from 'containers/Feedback/RegisterTesterModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './rootReducer';
import { updateUser, logout } from './rootSlice';

export default function App(): JSX.Element {
  
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.userReducer)

  useEffect(() => {
      dispatch(updateUser())
  }, [])

  return (
      <Router>
        <NavBar theme="dark" user={user} handleLogout={() => dispatch(logout())}/>
        <Routes>
          <Route path='/' element={<ProfileHome />} />
          <Route path='/login' element={<CustomSignIn handleSignup={updateUser}/>}/>
          <Route path='/signup' element={ <CustomSignUp />}/>
          <Route path="/entity/:id" element={<Entity />} />
        </Routes>

        <InsertProfileModal /> 
        <RegisterTesterModal />
      </Router>
  )
}