import { ITriple, IEntity } from 'agora-types';
import PropertyEditor from 'components/PropertyEditor/PropertyEditor';
import React from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { List, Card, Collapse, Divider, Tag, Descriptions, Button } from 'antd';
import { toTitleCase } from 'utils/stringUtils';
import Map from '../../../ObjectRender/Map/Map'
import { ParsedEntity } from 'components/Visualizations/Triage/triage';
import {
    TwitterOutlined,
    YoutubeOutlined,
    FacebookOutlined,
    InstagramOutlined,
    RedditOutlined
  } from '@ant-design/icons';
// import { mockData } from '../../../../containers/Profile/data'
import './profile.css'
import { Link } from 'react-router-dom';
import { AgoraData } from '../../../../containers/Profile/data'

interface ProfileFeedItemProps {
    entity: ParsedEntity
    onSelect: () => void
}

export default function ProfileFeedItem(props: ProfileFeedItemProps): JSX.Element {

    let label: React.ReactNode = null
    let description: React.ReactNode = ''
    let dateEntered: string = ''
    let instanceOf: string = ''
    let map: React.ReactNode = null
    let leftovers: ITriple[] = [...props.entity.mapTriples, ...props.entity.timelineTriples, ...props.entity.networkTriples, ...props.entity.otherTriples]
    const { entity } = props

    const getIcon = (source: string, link: string) : JSX.Element => {
        let icon: JSX.Element = null

        switch (source) {
            case 'reddit':
                icon = <Tag icon={<RedditOutlined />} color="#FF4301">
                <a href={link} target="_blank"> Reddit </a> 
            </Tag>
                break
            case 'instagram':
                icon = <Tag icon={<InstagramOutlined />} color="#F56040">
                <a href={link} target="_blank"> Instagram </a> 
            </Tag>
                break
            case 'twitter':
                icon = <Tag icon={<TwitterOutlined />} color="#55acee">
                    <a href={link} target="_blank"> Twitter </a> 
                </Tag>
                break
            case 'youtube':
                icon = <Tag icon={<YoutubeOutlined />} color="#cd201f">
                <a href={link} target="_blank"> Youtube </a> 
            </Tag>
                break
        }
        return icon
    }

    // const accounts = mockData[entity.label.object.value.replace(/\s/g, "").toLowerCase()].accounts

    const accounts = AgoraData[entity.label.object.value.toLowerCase().replace(/\s/g, "")]
    return (
        <div>
               <Link to={`/entity/${entity.label.object.value.toLowerCase().replace(/\s/g, "")}`}> <h2 className="mobile"> {entity.label.object.value} </h2> </Link>
        <Card 
            title={<Link to={`/entity/${entity.label.object.value.toLowerCase().replace(/\s/g, "")}`}> {entity.label.object.value} </Link>}
            actions={[
                <div onClick={() => props.onSelect()}> View Page </div>
            ]}
            extra={
                [
                    getIcon('youtube', `https://youtube.com/${accounts['youtube']}`),
                    getIcon('twitter', `https://twitter.com/${accounts['twitter']}`),
                    getIcon('instagram', `https://instagram.com/${accounts['instagram']}`),
                    <Tag color="blue">{entity.instance.object.value}</Tag>
                ]}
        >
          {entity.description.object.value}
        </Card>
        </div>
    )
}